import React from "react";
import { useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo.js";
import Slider from "../components/Slider-3";
import CpostOut from "../components/cpostOut";
import WorkDetails from "../components/workDetails";
import { Link, graphql } from "gatsby";
import { gsap } from "gsap";
import Newsletter from "../components/newsletter.js";
import CTA from "../components/cta.js";

const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link
        to={`/kariera/${props.url}#work`}
        className="button standard-button is-size-4"
      >
        {props.text}
      </Link>
    );
  } else {
    return (
      <span disabled className="button standard-button is-size-4">
        {props.text}
      </span>
    );
  }
};

const CareerPage = props => {
  const {
    pageContext: { first, group, index, last }
  } = props;
  const previousUrl = index - 1 === 1 ? "" : (index - 1).toString();
  const nextUrl = (index + 1).toString();

  useEffect(() => {
    gsap.to("#a1", { x: 500, duration: 0 });
    gsap.to("#a1", { x: 0, duration: 4 });
    gsap.to("#a2", { x: -500, duration: 0 });
    gsap.to("#a2", { x: 50, duration: 4, delay: 1 });
  }, []);

  return (
    <>
      <Layout>
        <SEO title="Kariera" />
        <div className="x-wrapper">
          <div
            className="columns"
            style={{
              minHeight: "500px",
              padding: "0px",
              margin: "0px",
              alignItems: "center",
              backgroundColor: "white"
            }}
          >
            <div
              id="lcol"
              className="column nopad"
              style={{
                justifyContent: "center",
                padding: "2% 0%",
                maxWidth: "25%"
              }}
            >
              <div className="nopadleft" style={{ paddingLeft: "3rem" }}>
                <h1
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2em",
                    marginTop: "30px"
                  }}
                  className="title"
                >
                  <strong style={{ color: "#00ff00" }}>Kariera </strong>
                  <br />
                  <span style={{ fontSize: ".5em" }}>
                    {" "}
                    Zobacz aktualne
                    <br /> oferty pracy
                  </span>
                </h1>
                <hr
                  style={{
                    maxWidth: "150px",
                    backgroundColor: "#333333",
                    height: "3px"
                  }}
                />
                <div
                  className="show-mob"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <img
                    id="mobile-x"
                    src="/img/x.svg"
                    style={{
                      marginBottom: "20px",
                      maxWidth: "500px",
                      marginTop: "-75px"
                    }}
                    width="auto"
                    height="auto"
                    alt="Rexer engineering"
                  />
                </div>
                <p>
                  Przeprowadzamy rekrutacje na poniższe stanowiska, jeżeli nie
                  boisz się wyzwań, lubisz pracę zespołową i posiadasz
                  odpowiednie kwalifikacje, zapraszamy do złożenia aplikacji.
                </p>
                <a href="#work" className="button rexer-button">
                  Sprawdź nasze wakaty
                </a>
              </div>
              <br />
              <div
                className="column mob-hide is-two-thirds"
                style={{ position: "relative" }}
              >
                <img
                  id="a2"
                  style={{
                    marginTop: "50px",
                    marginBottom: "-30%",
                    marginLeft: "-20px",
                    maxWidth: "350px",
                    opacity: "1"
                  }}
                  src="/img/helmet.png"
                  width="auto"
                  alt="animacja-kula"
                />
              </div>
            </div>

            <div
              id="midcol"
              className="column"
              style={{ width: "50%", marginTop: "-100px", textAlign: "center" }}
            >
              <img
                src="/img/x.svg"
                style={{ marginBottom: "0" }}
                width="auto"
                height="auto"
                alt="Rexer engineering"
              />
              <p
                id="paddmob"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <a
                  href="#main"
                  style={{ border: "0px solid white", marginTop: "0px" }}
                  className="button standard-button"
                >
                  ↓
                </a>
              </p>
            </div>

            <div
              id="rcol"
              className="column"
              style={{
                maxWidth: "25%",
                margin: "0px",
                marginTop: "-175px",
                padding: "0px",
                alignItems: "end",
                display: "block",
                justifyContent: "center",
                marginRight: "3em"
              }}
            >
              <div style={{ textAlign: "right", width: "100%" }}>
                <img
                  id="a1"
                  src="/img/k1.png"
                  width="100%"
                  height="auto"
                  alt="Rexer engineering"
                  style={{
                    minWidth: "300px",
                    maxWidth: "400px",
                    opacity: "1",
                    marginBottom: "0px",
                    borderBottom: "3px solid #f6f6f6",
                    backgroundImage: "url(/img/hero-bg-home.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                  }}
                />
              </div>
              <div
                className="deco-w"
                style={{
                  border: "5px solid #333333",
                  padding: "20px",
                  marginTop: "3em",
                  marginLeft: "1rem"
                }}
              >
                <p
                  className="deco"
                  style={{ textAlign: "right", backgroundColor: "white" }}
                >
                  Stawiamy na możliwość rozwoju osobistego, zapewniając{" "}
                  <strong>najwyższe standardy warunków pracy</strong>.
                </p>
              </div>
              <p
                id="marginmob"
                style={{ textAlign: "right", marginTop: "3em" }}
              >
                <Link className="button rexer-button" to="/kontakt#form">
                  Formularz kontaktowy
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div
          id="main"
          className="is-bold"
          style={{
            textAlign: "left",
            backgroundColor: "#f6f6f6",
            padding: "2% 5%"
          }}
        >
          <h2
            className="subtitle is-size-5"
            style={{
              borderLeft: "5px solid #00ff00",
              marginTop: "50px",
              paddingLeft: "10px",
              marginLeft: "-10px"
            }}
          >
            <strong style={{ textTransform: "uppercase" }}>
              REKRUTACJA {new Date().getFullYear()} TRWA, SPRAWDŹ AKTUALNE
              OFERTY PRACY
            </strong>
          </h2>
          <br />
          <p className="is-size-5" style={{ fontWeight: "400" }}>
            Umowa o pracę lub kontrakt inżynierski, atrakcyjny system
            wynagrodzeń i stabilne warunki zatrudnienia, a do tego komfortowe
            warunki pracy, ubezpieczenie na życie, opieka medyczna, przyjazna
            atmosfera i dużo aktywości - to możemy zaoferować Tobie.
            <br />
            <br />
            <br />
            Szukamy ludzi z odpowiednimi kwalifikacjami, jak i tych którzy chcą
            się uczyć.
            <br />
            <br />
            Poniżej znajdziecie Państwo szczegóły każdej z ofert w tym
            oczekiwane kwalifikacje.
          </p>
        </div>

        <div style={{ textAlign: "left", margin: "2% 5%" }}>
          <h2
            className="subtitle is-size-4"
            style={{
              borderLeft: "5px solid #00ff00",
              fontWeight: "600",
              textTransform: "uppercase",
              marginTop: "50px",
              paddingLeft: "10px"
            }}
          >
            Co oferujemy?
          </h2>
          <br />
          <div style={{ padding: "2.5% 10%" }}>
            <WorkDetails />
          </div>
        </div>

        <div style={{ padding: "0 3em 0em 3em", marginTop: "-3%" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center"
            }}
          >
            <CpostOut posts={group} />
          </div>

          <div className="buttons is-centered" style={{ marginTop: "35px" }}>
            <PaginationLink test={first} url={previousUrl} text="<" />
            <PaginationLink test={last} url={nextUrl} text=">" />
          </div>

          <Slider />
          {/*  <div className="columns">
      <div className="is-bold column" style={{textAlign:'center', padding:'0% 5% 2.5% 5%'}}>
      <p className="is-size-3">Zobacz nasze realizacje</p>
      <p>Sprawdź jakie efekty przyniosły nasze prace.</p>
      <br />
      <Link className="button rexer-button is-size-5" to="/">
        Realizacje
      </Link>
      </div>

      <div className="is-bold column" style={{textAlign:'center', padding:'0% 5% 2.5% 5%'}}>
      <p className="is-size-3">Przeczytaj aktualności</p>
      <p>Życzymy miłej lektury.</p>
      <br />
      <Link className="button rexer-button is-size-5" to="/aktualnosci/">
        Aktualności
      </Link>
      </div>
      </div>
      <br /><br />

      <div style={{textAlign:'center',paddingBottom:'5%'}}>

      <p>Wróć do przeglądania</p>
      <br />
      <Link className="button rexer-button is-size-5" to="/">
      Home
      </Link>
      </div> */}
        </div>
        <Newsletter />
        <CTA />
      </Layout>
    </>
  );
};

export default CareerPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD. MM. YYYY")
            title
            language
            description
            templateKey
          }
        }
      }
    }
  }
`;
