import React from "react"

function WorkDetails() {
  return (
<>
<div id="odets">
<div id="work" className="columns">
<div className="column" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/w-icons/umowa-o-prace.svg"
width="55px" height="auto" />
</div>
<span style={{color:'#111'}}>
Umowa o pracę
</span>
</div>
<div className="column" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/w-icons/system-wynagrodzen.svg"
width="55px" height="auto" />
</div>
<span style={{color:'#111'}}>
Atrakcyjny system wynagrodzeń
</span>
</div>
<div className="column" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/w-icons/stabilne-warunki-pracy.svg"
width="55px" height="auto" />
</div>
<span style={{color:'#111'}}>
Stabilne warunki zatrudnienia
</span>
</div>
<div className="column" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/w-icons/komfortowe-warunki.svg"
width="55px" height="auto" />
</div>
<span style={{color:'#111'}}>
Komfortowe warunki pracy
</span>
</div>
</div>


<div className="columns">
<div className="column" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/w-icons/przyjazna-atmosfera.svg"
width="55px" height="auto" />
</div>
<span style={{color:'#111'}}>
Przyjazna atmosfera
</span>
</div>
<div className="column" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/w-icons/opieka-medyczna.svg"
width="55px" height="auto" />
</div>
<span style={{color:'#111'}}>
Opieka medyczna
</span>
  </div>
<div className="column" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/w-icons/ubezpieczenie-na-zycie.svg"
width="55px" height="auto" />
</div>
<span style={{color:'#111'}}>
Ubezpieczenie na życie
</span>
</div>

<div className="column" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/w-icons/praca-w-zespole.svg"
width="55px" height="auto" />
</div>
<span style={{color:'#111'}}>
Praca w zespole profesjonalistów
</span>
</div>

</div>


<div className="columns">
<div className="column" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/w-icons/mozliwosc-rozwoju.svg"
width="55px" height="auto" />
</div>
<span style={{color:'#111'}}>
Możliwość rozwoju zawodowego
</span>
</div>
<div className="column" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/w-icons/integracja-pracownikow.svg"
width="55px" height="auto" />
</div>
<span style={{color:'#111'}}>
Integracja pracowników
</span>
  </div>
<div className="column" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/w-icons/telefon-i-laptop.svg"
width="55px" height="auto" />
</div>
<span style={{color:'#111'}}>
Telefon i laptop
</span>
</div>

<div className="column" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/w-icons/samochod-sluzbowy.svg"
width="55px" height="auto" />
</div>
<span style={{color:'#111'}}>
Samochód służbowy<sup>*</sup>
</span>
</div>

</div>
</div>
<hr />
<p><sup>*</sup> Wybrane stanowiska</p>
</>
)
}
export default WorkDetails
