import React from 'react';
import { Link } from 'gatsby'


class CpostOut extends React.Component {

  render() {
     let posts = this.props.posts

  return (
    <>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug

        return (
          <div className="car box career" key={node.fields.slug}>
            <h3
            >
              <Link className="is-size-3" style={{ boxShadow: `none`,fontWeight:'600' }} to={`/${node.fields.slug}`}>
                {title}
              </Link>
            </h3>
          {/*<small>{node.frontmatter.date}</small>*/}
            <p
              dangerouslySetInnerHTML={{
                __html: node.excerpt,
              }}
            />
            <br />
            <Link className="is-size-6 is-large button rexer-button" to={`/${node.fields.slug}`}>
              zobacz szczegóły
            </Link>
          </div>
        )
      })}
    </>
  )
}
}

export default CpostOut
